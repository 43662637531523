// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("/opt/build/repo/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/build/repo/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-investor-information-tsx": () => import("/opt/build/repo/src/pages/investor-information.tsx" /* webpackChunkName: "component---src-pages-investor-information-tsx" */),
  "component---src-pages-rent-to-own-application-tsx": () => import("/opt/build/repo/src/pages/rent-to-own-application.tsx" /* webpackChunkName: "component---src-pages-rent-to-own-application-tsx" */),
  "component---src-pages-rental-application-tsx": () => import("/opt/build/repo/src/pages/rental-application.tsx" /* webpackChunkName: "component---src-pages-rental-application-tsx" */)
}

